import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Row, Col, Button } from "react-bootstrap";

const CustomersWeWorkWith = ({ heading, button, customers }) => {
	const [visibleCount, setVisibleCount] = useState(3); // Initial count of visible items
	const isMobile = typeof window !== "undefined" && window.innerWidth < 768;

	// Show all items when "Load More" is clicked
	const handleLoadMore = () => {
		setVisibleCount(customers.length);
	};

	return (
		<section className="customers-we-work-with my-5">
			<Container>
				<h2 className="text-center mb-4 mb-5 fs-1">{heading}</h2>
				<Row className="g-4">
					{customers
						.slice(0, isMobile ? visibleCount : customers.length)
						.map((customer, index) => {
							const imageData = getImage(customer.image.localFile);
							return (
								<Col md={4} key={index} className="mb-4 text-center">
									<div className="position-relative">
										{imageData && (
											<GatsbyImage
												className="w-100"
												image={imageData}
												alt={customer.image.altText}
											/>
										)}
										<div
											style={{ background: "rgba(16, 59, 94, 0.90)" }}
											className="w-100 position-absolute start-0 p-2 text-center bottom-0"
										>
											<p className="mt-2 fs-5 text-secondary">
												{customer.heading}
											</p>
										</div>
									</div>
								</Col>
							);
						})}
				</Row>
				{/* "Load More" button only visible on mobile and when not all items are displayed */}
				{isMobile && visibleCount < customers.length && (
					<div className="text-center mt-4">
						<Button
							className=" mt-2 px-4 w-100 w-md-auto d-inline-block text-uppercase"
							variant="outline-primary"
							onClick={handleLoadMore}
						>
							view more
						</Button>
					</div>
				)}
				{button && (
					<div className="text-center mt-2">
						<Button
							className=" mt-md-2 px-4 w-100 w-md-auto d-inline-block text-uppercase"
							variant="primary"
							href={button.url}
						>
							{button.title}
						</Button>
					</div>
				)}
			</Container>
		</section>
	);
};

export default CustomersWeWorkWith;
