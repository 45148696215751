// src/components/TextSection.js
import React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";

const TextSection = ({ heading, body, button }) => {
	return (
		<section className="text-section py-5 py-lg-7 bg-light-grey">
			<Container>
				<Row className="justify-content-center">
					<Col className="text-center" lg={8}>
						<h2 className="text-center fs-1 mb-4">{heading}</h2>
						<SafeHtmlParser htmlContent={body} />
						{button && (
							<div className="text-center mt-4">
								<Button
									className=" mt-2 px-4 w-100 w-md-auto d-inline-block text-uppercase"
									variant="primary"
									href={button.url}
								>
									{button.title}
								</Button>
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default TextSection;
