import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Marquee from "react-fast-marquee"; // Import the Marquee component
import { GatsbyImage, getImage } from "gatsby-plugin-image"; // Assuming logos are fetched via Gatsby

const OurClients = ({ logos }) => {
	return (
		<section className="our-clients my-5">
			<Container>
				{/* Marquee for mobile (md and down) */}
				<div className="d-lg-none">
					<Marquee gradient={false} speed={50}>
						{logos.map((logo, index) => {
							const image = getImage(
								logo.image.localFile.childImageSharp.gatsbyImageData
							);
							return (
								<div
									key={index}
									className="mx-3"
									style={{ display: "inline-block" }}
								>
									{image && (
										<GatsbyImage
											image={image}
											alt={logo.altText}
											style={{ maxWidth: "100px" }}
										/>
									)}
								</div>
							);
						})}
					</Marquee>
				</div>

				{/* Static grid for desktop (md and up) */}
				<div className="d-none d-lg-block">
					<Row className="justify-content-center g-5 align-items-center">
						{logos.map((logo, index) => {
							const image = getImage(
								logo.image.localFile.childImageSharp.gatsbyImageData
							);
							return (
								<Col key={index} lg={true} className=" text-center">
									{image && (
										<GatsbyImage
											image={image}
											alt={logo.altText}
											className="w-100"
										/>
									)}
								</Col>
							);
						})}
					</Row>
				</div>
			</Container>
		</section>
	);
};

export default OurClients;
