import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { navigate } from "gatsby";

function ContactForm({ btnText, isBackground, adPage }) {
	const [state, handleSubmit] = useForm("mldedabe");
	const [gclid, setGclid] = useState("");

	useEffect(() => {
		// Capture gclid from URL query parameters
		const urlParams = new URLSearchParams(window.location.search);
		const gclidParam = urlParams.get("gclid");
		if (gclidParam) {
			setGclid(gclidParam);
		}
	}, []);

	const handleFormSubmit = (event) => {
		// Capture form data
		const formData = new FormData(event.target);
		const customerEmail = formData.get("_replyto");
		const customerPhoneNumber = formData.get("telephone");
		const customerName = formData.get("name");

		// Call the formspree handleSubmit
		handleSubmit(event).then(() => {
			// Push to dataLayer after form submit
			if (typeof window !== "undefined" && window.dataLayer) {
				window.dataLayer.push({
					event: "generate_lead",
					form_name: "ContactForm", // You can change this based on the form name
					customer_email: customerEmail,
					customer_phone_number: customerPhoneNumber,
				});
			}
		});
	};

	useEffect(() => {
		// Redirect to thank-you page when form submission succeeds
		if (state.succeeded) {
			navigate("/thank-you");
		}
	}, [state.succeeded, navigate]);

	// Inline styles for custom input and textarea
	const customInputStyle = {
		border: "none",
		borderBottom: "1px solid #3B6C70",
		boxShadow: "none",
		borderRadius: "0",
	};

	const customTextareaStyle = {
		border: "1px solid #3B6C70",
		boxShadow: "none",
		borderRadius: "0",
	};

	return (
		<>
			<Form className="w-100" onSubmit={handleFormSubmit}>
				<Form.Group controlId="name" className="mb-3">
					<Form.Control
						required
						name="name"
						placeholder="Name *"
						type="text"
						style={customInputStyle} // Apply inline custom style
					/>
				</Form.Group>
				<ValidationError prefix="Name" field="name" errors={state.errors} />
				<Form.Group className="mb-3" controlId="telephone">
					<Form.Control
						required
						type="tel"
						name="telephone"
						placeholder="Phone Number *"
						style={customInputStyle} // Apply inline custom style
					/>
				</Form.Group>

				<ValidationError
					prefix="Telephone"
					field="telephone"
					errors={state.errors}
				/>
				<Form.Group className="mb-3" controlId="email">
					<Form.Control
						required
						name="_replyto"
						type="email"
						placeholder="Email *"
						style={customInputStyle} // Apply inline custom style
					/>
				</Form.Group>
				<ValidationError prefix="Email" field="email" errors={state.errors} />

				<Form.Group className="mb-3" controlId="message">
					<Form.Control
						name="message"
						placeholder="Message *"
						as="textarea"
						rows={4}
						style={customTextareaStyle} // Apply inline custom style
					/>
				</Form.Group>

				<ValidationError
					prefix="Message"
					field="message"
					errors={state.errors}
				/>

				{/* Hidden field for gclid */}
				{gclid && (
					<Form.Control
						type="hidden"
						name="gclid"
						value={gclid}
						style={{ display: "none" }}
					/>
				)}

				<Button
					className="btn btn-primary w-100 "
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					SUBMIT
				</Button>
			</Form>
		</>
	);
}

export default ContactForm;
