// src/components/SideImageRight.js
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Row, Col, Button } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";

const SideImageRight = ({ heading, body, button, image }) => {
	const imageData = getImage(image.localFile);

	return (
		<section className="side-image-right bg-light-grey py-5 py-lg-7">
			<Container>
				<Row className=" g-5 justify-content-between align-items-center">
					<Col lg={6} className=" text-md-left">
						<h2 className="mb-4 fs-1">{heading}</h2>

						<SafeHtmlParser htmlContent={body} />

						{button && (
							<Button
								className="mb-3 mt-2 px-4 w-100 w-md-auto d-inline-block text-uppercase"
								variant="primary"
								href={button.url}
							>
								{button.title}
							</Button>
						)}
					</Col>
					<Col lg={5} className="">
						{imageData && (
							<GatsbyImage
								className="w-100"
								image={imageData}
								alt={image.altText}
							/>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default SideImageRight;
