import React, { useState } from "react";
import {
	Container,
	Row,
	Col,
	Accordion,
	useAccordionButton,
} from "react-bootstrap";
import { FaPlus, FaMinus } from "react-icons/fa"; // Import plus and minus icons
import { SafeHtmlParser } from "./safe-html-parser";

const CustomToggle = ({ children, eventKey, callback, isOpen }) => {
	const decoratedOnClick = useAccordionButton(eventKey, callback);
	return (
		<div
			onClick={decoratedOnClick}
			style={{
				backgroundColor: "#EBF0F0", // Background color for header only
				color: "#3B6C70", // Text and icon color
				padding: "1rem",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				cursor: "pointer",
				fontWeight: "600",
				marginBottom: "3px",

				borderBottom: "none",
			}}
		>
			{children}
			{isOpen ? <FaMinus /> : <FaPlus />}{" "}
			{/* Toggle between plus and minus icons */}
		</div>
	);
};

const FaqsSection = ({ faqs }) => {
	const [openItem, setOpenItem] = useState(null);

	const handleToggle = (index) => {
		setOpenItem(openItem === index ? null : index);
	};

	return (
		<section className="faqs-section py-5 py-lg-7">
			<Container>
				<Row className="justify-content-center">
					<Col lg={8}>
						<Accordion>
							{faqs.map((faq, index) => (
								<Accordion.Item
									eventKey={index.toString()}
									key={index}
									style={{ border: "none" }}
								>
									<CustomToggle
										className="lato-regular"
										eventKey={index.toString()}
										isOpen={openItem === index}
										callback={() => handleToggle(index)}
									>
										{faq.faqFields.question}
									</CustomToggle>
									<Accordion.Body
										style={{
											backgroundColor: "#FFFFFF",
											color: "#4F4F4F",
											fontSize: "1rem",
											lineHeight: "1.5",
										}}
									>
										<SafeHtmlParser htmlContent={faq.faqFields.answer} />
									</Accordion.Body>
								</Accordion.Item>
							))}
						</Accordion>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default FaqsSection;
