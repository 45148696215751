// src/components/MapSection.js
import React from "react";
import { Container } from "react-bootstrap";

const MapSection = ({ heading }) => {
	return (
		<section className="map-section pb-5 pb-lg-7">
			<Container>
				<h2 className="text-center fs-1 mb-5">{heading}</h2>
				{/* Embed or integrate your map component here */}
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9450.53764882825!2d-1.309710074066494!3d53.689140858248415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48796a495a2bffff%3A0x17f35f34a5012b!2sMobile%20Cad%20Surveying%20Solutions%20Ltd!5e0!3m2!1sen!2suk!4v1730118009157!5m2!1sen!2suk"
					width="100%"
					height="500"
					style="border:0;"
					allowfullscreen=""
					loading="lazy"
					referrerpolicy="no-referrer-when-downgrade"
				></iframe>
			</Container>
		</section>
	);
};

export default MapSection;
