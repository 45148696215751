import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ReviewsSection = ({ selectedReviews }) => {
	return (
		<section className="reviews-section my-5 my-lg-7">
			<Container>
				<Row className="justify-content-center d-none d-md-flex">
					<Col className="text-center">
						<Carousel
							indicators={false}
							nextIcon={
								<FaChevronRight className="carousel-control-next-icon" />
							}
							prevIcon={
								<FaChevronLeft className="carousel-control-prev-icon" />
							}
							interval={null} // Disable auto sliding
						>
							{selectedReviews.map((review, index) => (
								<Carousel.Item key={index}>
									<div className="d-flex justify-content-center">
										<div className="w-md-60 d-block w-lg-50">
											<p className="testimonial-text fs-5">
												{review.reviewFields.review}
											</p>
											<p className="testimonial-author">
												{review.reviewFields.name}
											</p>
										</div>
									</div>
								</Carousel.Item>
							))}
						</Carousel>
					</Col>
				</Row>
				<Row className="justify-content-center d-md-none">
					<Col className="text-center">
						<Carousel
							touch
							indicators={true}
							controls={false}
							nextIcon={
								<FaChevronRight className="carousel-control-next-icon" />
							}
							prevIcon={
								<FaChevronLeft className="carousel-control-prev-icon" />
							}
							interval={null} // Disable auto sliding
						>
							{selectedReviews.map((review, index) => (
								<Carousel.Item key={index}>
									<div className="d-flex justify-content-center">
										<div className="w-md-60 d-block w-lg-50">
											<p className="testimonial-text fs-5">
												{review.reviewFields.review}
											</p>
											<p className="testimonial-author">
												{review.reviewFields.name}
											</p>
										</div>
									</div>
								</Carousel.Item>
							))}
						</Carousel>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ReviewsSection;
