// src/components/FormSection.js
import React from "react";
import { BgImage } from "gbimage-bridge";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Row, Col, Button } from "react-bootstrap";
import ContactForm from "./contactForm"; // Import your ContactForm component
import logo from "../images/icon.png"; // Import the logo
import star from "../images/Star.svg"; // Import the star icon
import { SafeHtmlParser } from "./safe-html-parser";

const FormSection = ({ heading, backgroundImage }) => {
	const imageData = getImage(backgroundImage.localFile);

	return (
		<section id="form" className="hero-section">
			{/* BgImage for background */}
			<BgImage image={imageData} className="bg-cover position-relative">
				<div
					style={{
						zIndex: 0,
						background:
							"linear-gradient(0deg, rgba(38, 75, 78, 0.80) 0%, rgba(38, 75, 78, 0.80) 100%)",
					}}
					className="position-absolute top-0 start-0 w-100 h-100"
				></div>
				<Container style={{ zIndex: 2 }} className="py-4 position-relative">
					<Row className=" justify-content-center align-items-center  py-5 py-lg-7">
						<Col md={6} lg={5} xl={4}>
							<h2 className="text-center text-white fs-1  mb-5">{heading}</h2>
							<div className="bg-white rounded p-4 mx-auto">
								<ContactForm />
							</div>
						</Col>
					</Row>
				</Container>
			</BgImage>
		</section>
	);
};

export default FormSection;
