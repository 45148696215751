// src/components/HeroSection.js
import React from "react";
import { BgImage } from "gbimage-bridge";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Row, Col, Button } from "react-bootstrap";
import ContactForm from "./contactForm"; // Import your ContactForm component
import logo from "../images/icon.png"; // Import the logo
import star from "../images/Star.svg"; // Import the star icon
import { SafeHtmlParser } from "./safe-html-parser";

const HeroSection = ({
	heading,
	body,
	numberOfReviews,
	button,
	backgroundImage,
}) => {
	const bgImage = getImage(backgroundImage.localFile); // Background image for BgImage

	return (
		<section className="hero-section">
			{/* BgImage for background */}
			<BgImage image={bgImage} className="bg-cover position-relative">
				<div
					style={{
						zIndex: 0,
						background:
							"linear-gradient(0deg, rgba(38, 75, 78, 0.80) 0%, rgba(38, 75, 78, 0.80) 100%)",
					}}
					className="position-absolute top-0 start-0 w-100 h-100"
				></div>
				<Container style={{ zIndex: 2 }} className="py-4 position-relative">
					<Row>
						<Col className="text-center ">
							<img
								src={logo}
								alt="Company Logo"
								className="mb-3"
								style={{ maxWidth: "150px" }}
							/>
						</Col>
					</Row>
					<Row className="align-items-center justify-content-between g-4 py-5 py-lg-7">
						{/* Left side content */}
						<Col md={6} lg={5} className="text-white text-center text-md-start">
							<h1>{heading}</h1>

							<SafeHtmlParser htmlContent={body} />

							{/* Button */}
							{button && (
								<Button
									variant="white"
									href={button.url}
									className="mb-3 mt-2 px-4 text-primary w-100 w-md-auto text-uppercase"
								>
									{button.title}
								</Button>
							)}

							{/* Reviews section */}
							<div className="d-flex align-items-center justify-content-center justify-content-md-start">
								{[...Array(5)].map((_, index) => (
									<img
										key={index}
										src={star}
										alt="Star"
										className="me-1"
										style={{ width: "20px" }}
									/>
								))}{" "}
								<span className="ms-2">5.0</span>
								<span className="mx-2"> | </span>
								<span>{numberOfReviews} Google Reviews</span>
							</div>
						</Col>

						{/* Right side form */}
						<Col md={6} lg={5} xl={4}>
							<div className="bg-white rounded p-4 mx-auto">
								<h3 className="text-center text-primary fs-4 mb-4">
									Request a quote
								</h3>
								<ContactForm />
							</div>
						</Col>
					</Row>
				</Container>
			</BgImage>
		</section>
	);
};

export default HeroSection;
