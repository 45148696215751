import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import newIcon from "../images/new-icon.svg"; // Ensure this path is correct

const ItemsSection = ({ items }) => {
	return (
		<section
			className="items-section  bg-primary py-5"
			style={{
				color: "#ffffff", // Light text color
			}}
		>
			<Container>
				<Row className="justify-content-center g-3 g-lg-6">
					{items.map((item, index) => (
						<Col
							md={6}
							lg={3}
							key={index}
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								textAlign: "center",
								marginBottom: "1.5rem",
							}}
						>
							<Card
								className="text-white"
								style={{ border: "none", backgroundColor: "transparent" }}
							>
								<Card.Body
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										padding: 0,
									}}
								>
									<img
										src={newIcon}
										alt="Icon"
										style={{
											width: "55px",

											marginBottom: "20px",
										}}
									/>
									<Card.Title>{item.heading}</Card.Title>
									<Card.Text>{item.body}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
			</Container>
		</section>
	);
};

export default ItemsSection;
